import React, { Component } from 'react'
import './scss/style.scss'
import {
  getSpinnerMessage,
  getToastErrors,
  getToastMessage,
  isShowErrorToast,
  isShowSpinner,
  isShowSuccessToast,
} from './store/selectors/global'
import { connect } from 'react-redux'
import { CToaster } from '@coreui/react-pro'
import {
  getAppName,
  getEmailVerifiedAt,
  makeSelectTokenId,
  myPermission,
  myRole,
} from './views/user/selectors'
import { LoadingOverlay, ErrorToast, SuccessToast } from './components/common'
import {
  hideErrorToastAction,
  hideSpinnerAction,
  hideSuccessToastAction,
} from './store/actions/global'
import { setToken } from 'utils/request'
import { messageEchoInit } from 'utils/messageUtil'
import { getKeysByName, getMyPermission, getMyProfile, logout } from './views/user/actions'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap/dist/js/bootstrap.min.js'

import './assets/plugins/fontawesome/css/fontawesome.min.css'
import './assets/plugins/fontawesome/css/all.min.css'

import './scss/_client.scss'
import AppNavigator from './views/AppNavigator'

class App extends Component {
  constructor() {
    super()
    this.state = {
      toast: null,
      loading: false,
    }
  }
  componentDidMount() {
    document.title = this.props.appName
    setToken(this.props.token)
    this.props.hideErrorToast()
    this.props.hideSuccessToast()
    this.props.hideSpinner()
    this.init().then()
  }

  async init() {
    await this.props.getAppNameFromKeys()
    if (this.props.token) {
      try {
        await this.props.getMyProfile()
        await this.props.getPermission()
      } catch (e) {
        await this.props.logout()
      }
    }
  }

  render() {
    // const isDeveloping = window.location.href.indexOf('localhost') > -1
    return (
      <>
        {this.props.isShowErrorToast && (
          <CToaster push={<ErrorToast errors={this.props.toastErrors} />} placement="top-end" />
        )}
        {this.props.isShowSuccessToast && this.props.toastMessage && (
          <CToaster push={<SuccessToast message={this.props.toastMessage} />} placement="top-end" />
        )}
        {this.props.isShowSpinner && this.props.spinnerMessage && (
          <LoadingOverlay message={this.props.spinnerMessage} />
        )}
        <AppNavigator {...this.props} />
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  token: makeSelectTokenId(state),
  isEmailVerified: getEmailVerifiedAt(state),
  isShowErrorToast: isShowErrorToast(state),
  toastErrors: getToastErrors(state),
  isShowSuccessToast: isShowSuccessToast(state),
  toastMessage: getToastMessage(state),
  isShowSpinner: isShowSpinner(state),
  spinnerMessage: getSpinnerMessage(state),
  appName: getAppName(state),
  permissions: myPermission(state),
  role: myRole(state),
})
const mapDispatchToProps = (dispatch) => {
  return {
    hideErrorToast: () => dispatch(hideErrorToastAction()),
    hideSuccessToast: () => dispatch(hideSuccessToastAction()),
    hideSpinner: () => dispatch(hideSpinnerAction()),
    getAppNameFromKeys: async () => dispatch(getKeysByName('app_name')),
    getPermission: async () => dispatch(getMyPermission()),
    getMyProfile: async () => dispatch(getMyProfile()),
    logout: async () => dispatch(logout()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
