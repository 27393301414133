import { fromJS } from 'immutable'

import pipeMutators from 'utils/mutator'
import { REHYDRATE } from 'redux-persist'
import { LOCATION_CHANGE } from 'react-router-redux'
import {
  LOGIN,
  REGISTER,
  LOGOUT,
  PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  VERIFY,
  APP_NAME_KEY,
  PERMISSIONS,
  MY_PROFILE,
  ROLE,
} from './actions'

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const resetError = () => (state) =>
  state.setIn(['status', 'error'], null).setIn(['status', 'tokenError'], null)
const resetUser = () => (state) => state.mergeIn(['user'], DEFAULT_USER)
const resetToken = () => (state) => state.mergeIn(['token'], DEFAULT_TOKEN)
const resetPasswordResetToken = () => (state) => state.setIn(['passwordResetToken'], '')

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'loading'], true)

const resetStatusSetTokenLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'tokenLoading'], true)

const resetStatusSetError = (error) => (state) =>
  resetStatus()(state).setIn(['status', 'error'], error || {})

const resetStatusSetLinkSent = () => (state) =>
  resetStatus()(state).setIn(['status', 'passwordResetLinkSent'], true)

const resetStatusSetPasswordReset = () => (state) =>
  resetStatus()(state).setIn(['status', 'passwordReset'], true)

const setAppName = (name) => (state) => state.setIn(['appName'], name)

const passwordChangeSuccess = () => (state) =>
  resetStatus()(state).setIn(['status', 'passwordChanged'], true)

const setUser = (email, id, full_name, email_verified_at, profile_img, membership) => (state) =>
  state.mergeIn(['user'], {
    email,
    id,
    full_name,
    profile_img,
    email_verified_at,
    membership,
  })

const setToken =
  (token, ttl = 10000000000000) =>
  (state) =>
    state.mergeIn(['token'], {
      id: token,
      ttl,
      validUntil: Date.now() + ttl,
    })

const setPasswordResetToken = (token) => (state) => state.setIn(['passwordResetToken'], token)

const setPermission = (permission) => (state) => {
  state.setIn(['permissions'], permission)
  const currentRole = state.getIn(['role'])
  const mutate = pipeMutators(state)
  if (permission && permission.length > 0 && permission[0].hasOwnProperty('name')) {
    if (currentRole) {
      const findIndex = permission.findIndex((element) => element.name === currentRole)
      if (findIndex < 0) {
        mutate([setRole(permission[0]['name'])])
      }
    } else {
      mutate([setRole(permission[0]['name'])])
    }
  } else {
    mutate([setRole('')])
  }
}

const setRole = (role) => (state) => {
  state.setIn(['role'], role)
}

const resetPermission = () => (state) => {
  state.setIn(['role'], '')
  return state.mergeIn(['permissions'], DEFAULT_PERMISSION)
}

const DEFAULT_STATUS = {
  loading: false,
  error: null,
  tokenLoading: false,
  tokenError: null,
  passwordResetLinkSent: false,
  passwordReset: false,
  passwordChanged: false,
}

const DEFAULT_USER = {
  email: '',
  id: '',
  full_name: '',
  domain: '',
  profile_img: '',
  email_verified_at: '',
  membership: 0,
}
const DEFAULT_TOKEN = {
  id: '',
  ttl: '',
  validUntil: false,
}

const DEFAULT_PERMISSION = []

const initialState = fromJS({
  status: DEFAULT_STATUS,
  user: DEFAULT_USER,
  token: DEFAULT_TOKEN,
  passwordResetToken: '',
  appName: 'Events On Call',
  permission: DEFAULT_PERMISSION,
  role: '',
})

function authReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case REHYDRATE:
      if (action.payload.auth) return resetStatus()(action.payload.auth)
      return state
    case LOCATION_CHANGE:
      return mutate(resetError())
    case LOGIN.DO:
      return mutate([resetStatusSetLoading(), resetUser(), resetToken()])
    case REGISTER.DO:
      return mutate([resetStatusSetLoading(), resetUser(), resetToken()])
    case VALIDATE_TOKEN.DO:
      return mutate([resetStatusSetTokenLoading(), resetUser(), resetToken()])
    case VERIFY.SUCCESS:
      return state.mergeIn(['user'], { email_verified_at: action.email_verified_at })
    case LOGIN.SUCCESS:
    case REGISTER.SUCCESS:
    case VALIDATE_TOKEN.SUCCESS:
      return mutate([
        resetStatus(),
        setUser(
          action.email,
          action.id,
          action.full_name,
          action.email_verified_at,
          action.profile_img,
          action.membership,
        ),
        setToken(action.token),
      ])
    case LOGIN.FAILED:
    case REGISTER.FAILED:
      return mutate([resetStatusSetError(action.error), resetUser(), resetToken()])
    case VERIFY.FAILED:
      return state.mergeIn(['user'], { email_verified_at: null })
    case LOGOUT:
      console.log('logout===================')
      return mutate([resetStatus(), resetUser(), resetToken(), resetPermission()])
    case PASSWORD_RESET_LINK.DO:
      return mutate(resetStatusSetLoading())
    case PASSWORD_RESET_LINK.SUCCESS:
      return mutate(resetStatusSetLinkSent())
    case PASSWORD_RESET_LINK.FAILED:
      return mutate(resetStatusSetError(action.error))

    case VERIFY_PASSWORD_TOKEN.DO:
      return mutate([resetStatusSetLoading(), resetPasswordResetToken()])
    case VERIFY_PASSWORD_TOKEN.SUCCESS:
      return mutate([resetStatus(), setPasswordResetToken(action.token)])
    case VERIFY_PASSWORD_TOKEN.FAILED:
      return mutate([resetStatusSetError(action.error), resetPasswordResetToken()])

    case RESET_PASSWORD.DO:
      return mutate(resetStatusSetLoading())
    case RESET_PASSWORD.SUCCESS:
      return mutate(resetStatusSetPasswordReset())
    case RESET_PASSWORD.FAILED:
      return mutate(resetStatusSetError(action.error))

    case CHANGE_PASSWORD.DO:
      return mutate(resetStatusSetLoading())
    case CHANGE_PASSWORD.SUCCESS:
      return mutate(passwordChangeSuccess())
    case CHANGE_PASSWORD.FAILED:
      return mutate(resetStatusSetError(action.error))

    case APP_NAME_KEY.DO:
      return mutate(resetStatusSetLoading())
    case APP_NAME_KEY.SUCCESS:
      return mutate([resetStatus(), setAppName(action.name)])
    case APP_NAME_KEY.FAILED:
      return mutate(resetStatusSetError(action.error))

    case PERMISSIONS.DO:
      return mutate(resetStatusSetLoading())
    case PERMISSIONS.SUCCESS:
      return mutate([resetStatus(), setPermission(action.data)])
    case PERMISSIONS.FAILED:
      return mutate(resetStatusSetError(action.error))

    case ROLE.SET:
      return mutate([setRole(action.data)])
    case ROLE.CLEAR:
      return mutate([setRole('')])

    case MY_PROFILE.DO:
      return mutate(resetStatusSetLoading(), resetUser())
    case MY_PROFILE.SUCCESS:
      console.log('MY_PROFILE:', action)
      return mutate([
        resetStatus(),
        setUser(
          action.email,
          action.id,
          action.full_name,
          action.email_verified_at,
          action.profile_img,
          action.membership,
        ),
      ])
    case MY_PROFILE.FAILED:
      return mutate(resetStatusSetError(action.error), resetUser(), resetToken())
    default:
      return state
  }
}

export default authReducer
