import sha1 from 'sha1'
import owasp from 'owasp-password-strength-test'

import { createTypes, createAction, transformNetworkError, createSetTypes } from 'utils/actions'

import { get, post, postWithAuth } from 'utils/request'
import { makeSelectTokenId } from './selectors'
import {
  hideSpinnerAction,
  showErrorToastAction,
  showSpinnerAction,
  showSuccessToastAction,
} from '../../store/actions/global'
import { navigateToPage } from '../../utils/route'

const REGISTER = createTypes('EOC/AuthPage/REGISTER')
const LOGIN = createTypes('EOC/AuthPage/LOGIN')
const LOGOUT = 'EOC/AuthPage/LOGOUT'
const PASSWORD_RESET_LINK = createTypes('EOC/AuthPage/PASSWORD_RESET_LINK')
const APP_NAME_KEY = createTypes('EOC/AuthPage/APP_NAME_KEY')
const PERMISSIONS = createTypes('EOC/AuthPage/PERMISSIONS')
const ROLE = createSetTypes('EOC/AuthPage/ROLE')
const MY_PROFILE = createTypes('EOC/AuthPage/MY_PROFILE')
const VERIFY_PASSWORD_TOKEN = createTypes('EOC/AuthPage/VERIFY_PASSWORD_TOKEN')
const RESET_PASSWORD = createTypes('EOC/AuthPage/RESET_PASSWORD')
const CHANGE_PASSWORD = createTypes('EOC/AuthPage/CHANGE_PASSWORD')
const VALIDATE_TOKEN = createTypes('EOC/AuthPage/VALIDATE_TOKEN')
const VERIFY = createTypes('EOC/AuthPage/VERIFY')

function loginActionSuccess(resp) {
  return loginAction.success({ ...resp.user, token: resp.token })
}

function registerActionSuccess(resp) {
  return registerAction.success({ ...resp.user, token: resp.token })
}
function verifyActionSuccess(resp) {
  return verifyAction.success(resp)
}
function verifyActionFailed() {
  return verifyAction.failed()
}
const registerAction = {
  do: () => createAction(REGISTER.DO, {}),
  success: (params) => {
    return createAction(REGISTER.SUCCESS, { ...params })
  },
  failed: (error) => createAction(REGISTER.FAILED, { error }),
}

const loginAction = {
  do: () => createAction(LOGIN.DO, {}),
  success: (params) => createAction(LOGIN.SUCCESS, { ...params }),
  failed: (error) => createAction(LOGIN.FAILED, { error }),
}
const verifyAction = {
  failed: () => createAction(VERIFY.FAILED),
  success: (params) =>
    createAction(VERIFY.SUCCESS, { email_verified_at: params.email_verified_at }),
}

const logoutAction = () => createAction(LOGOUT, {})

const passwordResetLinkAction = {
  do: () => createAction(PASSWORD_RESET_LINK.DO, {}),
  success: (email) => createAction(PASSWORD_RESET_LINK.SUCCESS, { email }),
  failed: (error) => createAction(PASSWORD_RESET_LINK.FAILED, { error }),
}

const appNameKeyAction = {
  do: () => createAction(APP_NAME_KEY.DO, {}),
  success: (response) => createAction(APP_NAME_KEY.SUCCESS, { name: response['data']['value'] }),
  failed: (error) => createAction(APP_NAME_KEY.FAILED, { error }),
}
const myPermissionAction = {
  do: () => createAction(PERMISSIONS.DO, {}),
  success: (response) => createAction(PERMISSIONS.SUCCESS, { data: response['user_permissions'] }),
  loginSuccess: (response) => createAction(PERMISSIONS.SUCCESS, { data: response['roles'] }),
  failed: (error) => createAction(PERMISSIONS.FAILED, { error }),
}
const myProfileAction = {
  do: () => createAction(MY_PROFILE.DO, {}),
  success: (response) => createAction(MY_PROFILE.SUCCESS, { ...response.user }),
  failed: (error) => createAction(MY_PROFILE.FAILED, { error }),
}

const resetPasswordAction = {
  do: () => createAction(RESET_PASSWORD.DO, {}),
  success: (token) => createAction(RESET_PASSWORD.SUCCESS, { token }),
  failed: (error) => createAction(RESET_PASSWORD.FAILED, { error }),
}

const changePasswordAction = {
  do: () => createAction(CHANGE_PASSWORD.DO, {}),
  success: () => createAction(CHANGE_PASSWORD.SUCCESS),
  failed: (error) => createAction(CHANGE_PASSWORD.FAILED, { error }),
}

const checkPasswordComplexity = (password) => {
  owasp.config({
    allowPassphrases: true,
    minLength: 6,
    // minOptionalTestsToPass: optionalTestsNeeded,
  })
  const result = owasp.test(password)

  if (!result.strong) {
    throw new Error(
      ['Password is not sufficiently strong.', result.requiredTestErrors.join(' ')].join(' '),
    )
  }
}

const register =
  (postData, isClient = false) =>
  async (dispatch) => {
    try {
      dispatch(registerAction.do())
      const action = isClient ? 'register-client' : 'register-oes'
      const resp = await post('auth', action, postData)
      dispatch(registerActionSuccess(resp))
      dispatch(showSuccessToastAction('Successfully registered.'))
      navigateToPage(isClient ? '/auth/welcome/client' : '/auth/welcome/oes')
    } catch (error) {
      dispatch(showErrorToastAction(error.body))
      dispatch(registerAction.failed(error.body))
    }
  }

const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(loginAction.do())
      dispatch(myPermissionAction.do())
      const resp = await postWithAuth('auth', 'login', email, password)
      dispatch(loginActionSuccess(resp))
      dispatch(myPermissionAction.loginSuccess(resp))
      localStorage.setItem('token', resp.token)
      dispatch(showSuccessToastAction('Successfully logged in.'))
      let destination = '/landing'
      if (resp['user'].hasOwnProperty('roles') && resp['user']['roles'].length > 0) {
        dispatch(myPermissionAction.loginSuccess(resp['user']))
        const perm = resp['user']['roles'][0]['name']
        if (perm === 'Admin') {
          destination = '/admin/dashboard'
        } else if (perm === 'OES') {
          destination = '/provider/home'
        } else if (perm === 'Client') {
          destination = '/client/home'
        }
      } else {
        dispatch(myPermissionAction.failed('No permission'))
      }
      navigateToPage(destination)
    } catch (error) {
      dispatch(showErrorToastAction(error.body))
      dispatch(myPermissionAction.failed(error.body))
      dispatch(loginAction.failed(error.body))
    }
  }

const logout = () => async (dispatch, getState) => {
  try {
    const token = makeSelectTokenId(getState())
    if (token) {
      await get('auth', 'logout', 'GET')
    }
    localStorage.setItem('token', null)
    dispatch(logoutAction())
    window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT
    // navigateToPage('/landing/home')
  } catch (error) {
    localStorage.setItem('token', null)
    dispatch(logoutAction())
    window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT
    dispatch(showErrorToastAction('An error occurred while logging out.'))
  }
}

const passwordResetLink = (data) => async (dispatch) => {
  try {
    dispatch(passwordResetLinkAction.do())
    await post('auth', 'reset-password', data)
    dispatch(passwordResetLinkAction.success(data))
    dispatch(
      showSuccessToastAction(
        'Successfully generated Reset Password link. Please check it in your mail box.',
      ),
    )
    // navigateToPage('/login')
  } catch (error) {
    dispatch(showErrorToastAction(error.body))
    dispatch(passwordResetLinkAction.failed(error))
  }
}

const changePassword = (oldPassword, newPassword) => async (dispatch, getState) => {
  try {
    dispatch(changePasswordAction.do())
    const token = makeSelectTokenId(getState())
    if (token) {
      checkPasswordComplexity(newPassword)
      const postData = {
        oldPassword: btoa(sha1(oldPassword)),
        newPassword: btoa(sha1(newPassword)),
      }
      await post('auth', 'password', postData, { Authorization: token })
      dispatch(changePasswordAction.success())
    }
  } catch (error) {
    dispatch(changePasswordAction.failed(transformNetworkError(error)))
  }
}

const resetPassword =
  (token, email, newPassword1, newPassword2, code = 0) =>
  async (dispatch) => {
    try {
      dispatch(resetPasswordAction.do())
      const postData = {
        token,
        email,
        new_password: newPassword1,
        confirm_password: newPassword2,
        code,
      }
      console.log('postData:', postData)
      await post('auth', 'set-password', postData)
      dispatch(resetPasswordAction.success(token))
      dispatch(showSuccessToastAction('Successfully Reset Your Password.'))
      navigateToPage('/auth/login')
    } catch (error) {
      dispatch(showErrorToastAction(error.body))
      dispatch(resetPasswordAction.failed(transformNetworkError(error)))
    }
  }

const verifyEmail = (code, email) => async (dispatch) => {
  dispatch(showSpinnerAction())
  try {
    const postData = { code, email }
    const resp = await post('auth', 'verify-email', postData)
    dispatch(verifyActionSuccess(resp))
    dispatch(hideSpinnerAction())
    navigateToPage('/auth/login')
  } catch (error) {
    dispatch(verifyActionFailed())
    dispatch(showErrorToastAction(error.body))
  }
}
const getContentsByName = (name) => async (dispatch) => {
  try {
    dispatch(showSpinnerAction())
    const resp = await get('base', `keys/get-content/${name}`)
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    // dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.resolve(error.body)
  }
}
const getKeysByName = (name) => async (dispatch) => {
  try {
    dispatch(appNameKeyAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('base', `keys/get-key/${name}`)
    dispatch(appNameKeyAction.success(resp))
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(appNameKeyAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.resolve(error.body)
  }
}

const getMyPermission = () => async (dispatch) => {
  try {
    dispatch(myPermissionAction.do())
    dispatch(showSpinnerAction('Loading Permissions...'))
    const resp = await get('admin', `my-permissions`)
    dispatch(myPermissionAction.success(resp))
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(myPermissionAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.resolve(error.body)
  }
}
const getMyProfile = () => async (dispatch) => {
  try {
    dispatch(myProfileAction.do())
    dispatch(showSpinnerAction('Loading Profile...'))
    const resp = await get('auth', `profile`)
    dispatch(myProfileAction.success(resp))
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(myProfileAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.resolve(error.body)
  }
}
const getPathFromRole = (role) => {
  if (role === 'Admin') {
    return '/admin/dashboard'
  } else if (role === 'OES') {
    return '/provider/home'
  } else if (role === 'Client') {
    return '/client/home'
  } else {
    return '/landing/home'
  }
}
const setRole = (role) => async (dispatch) => {
  const roleAction = {
    setData: (data) => createAction(ROLE.SET, { data }),
    clearData: () => createAction(ROLE.CLEAR, null),
  }
  try {
    dispatch(roleAction.setData(role))
    const redirectPath = getPathFromRole(role)
    navigateToPage(redirectPath)
  } catch (error) {
    dispatch(roleAction.clearData())
  }
}

export {
  LOGIN,
  REGISTER,
  LOGOUT,
  PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  VERIFY,
  APP_NAME_KEY,
  PERMISSIONS,
  MY_PROFILE,
  ROLE,
  checkPasswordComplexity,
  register,
  login,
  logout,
  changePassword,
  passwordResetLink,
  resetPassword,
  verifyEmail,
  getContentsByName,
  getKeysByName,
  getMyPermission,
  getMyProfile,
  setRole,
}
