export const createTypes = (typePrefix) => ({
  DO: `${typePrefix}_DO`,
  SUCCESS: `${typePrefix}_SUCCESS`,
  LIST: `${typePrefix}_LIST`,
  UPDATE: `${typePrefix}_UPDATE`,
  DELETE: `${typePrefix}_DELETE`,
  FAILED: `${typePrefix}_FAILED`,
})

export const createSetTypes = (typePrefix) => ({
  SET: `${typePrefix}_SET`,
  CLEAR: `${typePrefix}_CLEAR`,
})

export const createAction = (type, args) => ({
  ...args,
  type,
})

export const transformNetworkError = (error) => {
  if (!error.response) {
    return {
      status: 404,
      statusText: error.message,
    }
  }
  return {
    status: error.response.status,
    statusText: error.response.statusText,
  }
}
