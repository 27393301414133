import { createTypes, createAction } from 'utils/actions'

import { get, put, destroy, post, upload } from 'utils/request'
import {
  showErrorToastAction,
  showSpinnerAction,
  hideSpinnerAction,
  showSuccessToastAction,
} from '../../store/actions/global'

const KEY = createTypes('EOC/Admin/Key')
const CONTENT = createTypes('EOC/Admin/CONTENT')
const NOTIFICATION = createTypes('EOC/Admin/NOTIFICATION')
const OESTYPE = createTypes('EOC/Admin/OESTYPE')
const SPECIALTIES = createTypes('EOC/Admin/SPECIALTIES')
const MAJORCITIES = createTypes('EOC/Admin/MAJORCITIES')
const CERTIFICATIONS = createTypes('EOC/Admin/CERTIFICATIONS')
const getKeyAction = {
  do: () => createAction(KEY.DO, {}),
  success: (data) => createAction(KEY.SUCCESS, { data }),
  list: (data) => createAction(KEY.LIST, { data }),
  update: (id, data) => createAction(KEY.UPDATE, { id, data }),
  delete: (id) => createAction(KEY.DELETE, { id }),
  failed: (error) => createAction(KEY.FAILED, { error }),
}
const getNotificationAction = {
  do: () => createAction(NOTIFICATION.DO, {}),
  success: (data) => createAction(NOTIFICATION.SUCCESS, { data }),
  list: (data) => createAction(NOTIFICATION.LIST, { data }),
  update: (id, data) => createAction(NOTIFICATION.UPDATE, { id, data }),
  delete: (id) => createAction(NOTIFICATION.DELETE, { id }),
  failed: (error) => createAction(NOTIFICATION.FAILED, { error }),
}
const getContentAction = {
  do: () => createAction(CONTENT.DO, {}),
  success: (data) => createAction(CONTENT.SUCCESS, { data }),
  list: (data) => createAction(CONTENT.LIST, { data }),
  update: (id, data) => createAction(CONTENT.UPDATE, { id, data }),
  delete: (id) => createAction(CONTENT.DELETE, { id }),
  failed: (error) => createAction(CONTENT.FAILED, { error }),
}
const getOesTypeAction = {
  do: () => createAction(OESTYPE.DO, {}),
  success: (data) => createAction(OESTYPE.SUCCESS, { data }),
  list: (data) => createAction(OESTYPE.LIST, { data }),
  update: (id, data) => createAction(OESTYPE.UPDATE, { id, data }),
  delete: (id) => createAction(OESTYPE.DELETE, { id }),
  failed: (error) => createAction(OESTYPE.FAILED, { error }),
}
const getSpecialtiesAction = {
  do: () => createAction(SPECIALTIES.DO, {}),
  success: (data) => createAction(SPECIALTIES.SUCCESS, { data }),
  list: (data) => createAction(SPECIALTIES.LIST, { data }),
  update: (id, data) => createAction(SPECIALTIES.UPDATE, { id, data }),
  delete: (id) => createAction(SPECIALTIES.DELETE, { id }),
  failed: (error) => createAction(SPECIALTIES.FAILED, { error }),
}
const getCertificationsAction = {
  do: () => createAction(CERTIFICATIONS.DO, {}),
  success: (data) => createAction(CERTIFICATIONS.SUCCESS, { data }),
  list: (data) => createAction(CERTIFICATIONS.LIST, { data }),
  update: (id, data) => createAction(CERTIFICATIONS.UPDATE, { id, data }),
  delete: (id) => createAction(CERTIFICATIONS.DELETE, { id }),
  failed: (error) => createAction(CERTIFICATIONS.FAILED, { error }),
}
const getMajorCitiesAction = {
  do: () => createAction(MAJORCITIES.DO, {}),
  success: (data) => createAction(MAJORCITIES.SUCCESS, { data }),
  list: (data) => createAction(MAJORCITIES.LIST, { data }),
  update: (id, data) => createAction(MAJORCITIES.UPDATE, { id, data }),
  delete: (id) => createAction(MAJORCITIES.DELETE, { id }),
  failed: (error) => createAction(MAJORCITIES.FAILED, { error }),
}
const getKeys = () => async (dispatch) => {
  try {
    dispatch(getKeyAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', 'key')
    dispatch(getKeyAction.list(resp))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getKeyAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateKey = (id, data) => async (dispatch) => {
  try {
    dispatch(getKeyAction.do())
    dispatch(showSpinnerAction())
    if (id) {
      await put('admin', `key/${id}`, data)
      dispatch(getKeyAction.update(id, data))
    } else {
      const resp = await post('admin', `key`, data)
      dispatch(getKeyAction.list(resp))
    }
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getKeyAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const deleteKey = (id) => async (dispatch) => {
  try {
    dispatch(getKeyAction.do())
    dispatch(showSpinnerAction())
    await destroy('admin', `key/${id}`)
    dispatch(getKeyAction.delete(id))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getKeyAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const getContents = () => async (dispatch) => {
  try {
    dispatch(getContentAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', 'content')
    dispatch(getContentAction.list(resp))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getContentAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateContent = (id, data) => async (dispatch) => {
  try {
    dispatch(getContentAction.do())
    dispatch(showSpinnerAction())
    if (id) {
      await put('admin', `content/${id}`, data)
      dispatch(getContentAction.update(id, data))
    } else {
      const resp = await post('admin', `content`, data)
      dispatch(getContentAction.list(resp))
    }
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getContentAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const deleteContent = (id) => async (dispatch) => {
  try {
    dispatch(getContentAction.do())
    dispatch(showSpinnerAction())
    await destroy('admin', `content/${id}`)
    dispatch(getContentAction.delete(id))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getContentAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const getOesTypes = () => async (dispatch) => {
  try {
    dispatch(getOesTypeAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', 'oes-type?page_length=10&page=1')
    dispatch(getOesTypeAction.list(resp.data))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getOesTypeAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateOesType = (id, data) => async (dispatch) => {
  try {
    dispatch(getOesTypeAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    if (id) {
      resp = await put('admin', `oes-type/${id}`, data)
    } else {
      resp = await post('admin', `oes-type`, data)
    }
    dispatch(hideSpinnerAction())
    // dispatch(showSuccessToastAction('Oes Type updated successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getOesTypeAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteOesType = (id) => async (dispatch) => {
  try {
    dispatch(getOesTypeAction.do())
    dispatch(showSpinnerAction())
    const resp = await destroy('admin', `oes-type/${id}`)
    dispatch(getOesTypeAction.delete(id))
    dispatch(hideSpinnerAction())
    // dispatch(showSuccessToastAction('Oes Type deleted successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getOesTypeAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const getSpecialties = () => async (dispatch) => {
  try {
    dispatch(getSpecialtiesAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', 'specialties?page_length=10&page=1')
    dispatch(getSpecialtiesAction.list(resp.data))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getSpecialtiesAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateSpecialties = (id, data) => async (dispatch) => {
  try {
    dispatch(getSpecialtiesAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    if (id) {
      resp = await put('admin', `specialties/${id}`, data)
    } else {
      resp = await post('admin', `specialties`, data)
    }
    dispatch(hideSpinnerAction())
    // dispatch(showSuccessToastAction('Oes Type updated successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getSpecialtiesAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteSpecialties = (id) => async (dispatch) => {
  try {
    dispatch(getSpecialtiesAction.do())
    dispatch(showSpinnerAction())
    const resp = await destroy('admin', `specialties/${id}`)
    dispatch(getSpecialtiesAction.delete(id))
    dispatch(hideSpinnerAction())
    // dispatch(showSuccessToastAction('Oes Type deleted successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getSpecialtiesAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const getNotifications = () => async (dispatch) => {
  try {
    dispatch(getNotificationAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', 'notifications')
    dispatch(getNotificationAction.list(resp))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getNotificationAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateNotification = (id, data) => async (dispatch) => {
  try {
    dispatch(getNotificationAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    resp = await put('admin', `notifications/${id}`, data)
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getNotificationAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteNotification = (id, data) => async (dispatch) => {
  try {
    dispatch(getNotificationAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    resp = await destroy('admin', `notifications/${id}`, data)
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getNotificationAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const getMajorCities = (length) => async (dispatch) => {
  try {
    dispatch(getMajorCitiesAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', `major-cities?page_length=${length}&page=1&sort=name`)
    dispatch(getMajorCitiesAction.list(resp.data))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getMajorCitiesAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateMajorCities = (id, data) => async (dispatch) => {
  try {
    dispatch(getMajorCitiesAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    if (id) {
      resp = await put('admin', `major-cities/${id}`, data)
    } else {
      resp = await post('admin', `major-cities`, data)
    }
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getMajorCitiesAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteMajorCities = (id) => async (dispatch) => {
  try {
    dispatch(getMajorCitiesAction.do())
    dispatch(showSpinnerAction())
    const resp = await destroy('admin', `major-cities/${id}`)
    dispatch(getMajorCitiesAction.delete(id))
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getMajorCitiesAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const getCertifications = () => async (dispatch) => {
  try {
    dispatch(getCertificationsAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('admin', 'certifications?page_length=10&page=1')
    dispatch(getCertificationsAction.list(resp.data))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getCertificationsAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateCertifications = (id, data) => async (dispatch) => {
  try {
    dispatch(getCertificationsAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    if (id) {
      resp = await upload('admin', `update-certificate/${id}`, data)
    } else {
      resp = await upload('admin', `create-certificate`, data)
    }
    dispatch(hideSpinnerAction())
    // dispatch(showSuccessToastAction('Oes Type updated successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getCertificationsAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteCertifications = (id) => async (dispatch) => {
  try {
    dispatch(getCertificationsAction.do())
    dispatch(showSpinnerAction())
    const resp = await destroy('admin', `certifications/${id}`)
    dispatch(getCertificationsAction.delete(id))
    dispatch(hideSpinnerAction())
    // dispatch(showSuccessToastAction('Oes Type deleted successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getCertificationsAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

export {
  KEY,
  CONTENT,
  OESTYPE,
  SPECIALTIES,
  MAJORCITIES,
  CERTIFICATIONS,
  NOTIFICATION,
  getKeys,
  updateKey,
  deleteKey,
  getContents,
  updateContent,
  deleteContent,
  getOesTypes,
  updateOesType,
  deleteOesType,
  getSpecialties,
  updateSpecialties,
  deleteSpecialties,
  getCertifications,
  updateCertifications,
  deleteCertifications,
  getMajorCities,
  updateMajorCities,
  deleteMajorCities,
  getNotifications,
  updateNotification,
  deleteNotification,
}
