import { fromJS, List } from 'immutable'

import pipeMutators from 'utils/mutator'
import { REHYDRATE } from 'redux-persist'
import { PROVIDER_EVENT, SELECTED_EVENT_ID } from './actions'

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setData = (key, data) => (state) => state.setIn([key], data)
const updateData = (key, id, data) => (state) =>
  state.setIn([key, state.get(key).findIndex((item) => item.id === id)], data)

const deleteData = (key, id) => (state) =>
  state.deleteIn([key, state.get(key).findIndex((item) => item.id === id)])

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'loading'], true)

const resetStatusSetError = (error) => (state) =>
  resetStatus()(state).setIn(['status', 'error'], error || {})

const resetEventId = (id) => (state) => resetStatus()(state).setIn(['selected_event_id'], id)

const DEFAULT_STATUS = {
  loading: false,
  error: null,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
  oes_events: List([]),
  selected_event_id: '',
})

function providerReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case REHYDRATE:
      if (action.payload.client) return resetStatus()(action.payload.client)
      return state
    case PROVIDER_EVENT.DO:
      return mutate([resetStatusSetLoading(), setData('oes_events', [])])
    case PROVIDER_EVENT.SUCCESS:
    case PROVIDER_EVENT.LIST:
      return mutate([setData('oes_events', action.data), resetStatus()])
    case PROVIDER_EVENT.UPDATE:
      return mutate([updateData('oes_events', action.id, action.data), resetStatus()])
    case PROVIDER_EVENT.DELETE:
      return mutate([deleteData('oes_events', action.id), resetStatus()])
    case PROVIDER_EVENT.FAILED:
      return mutate([resetStatusSetError(action.error)])
    case SELECTED_EVENT_ID.SET:
      return mutate([resetEventId(action.data)])
    case SELECTED_EVENT_ID.CLEAR:
      return mutate([resetEventId('')])
    default:
      return state
  }
}

export default providerReducer
