import 'whatwg-fetch'
import apiRoot from '../_data/apiRoot'

let token = localStorage.getItem('token')
export function setToken(_token) {
  token = _token
}
async function checkStatus(response) {
  const json = await response.json()
  let error
  if (!response.ok) {
    // if(response.status === 500){
    //   localStorage.removeItem('token')
    //   if (window.location.href.indexOf('login') === -1) {
    //     window.location.href = '/login'
    //   }
    // }
    error = new Error()
    if (response.status > 399 && response.status < 500) {
      if (response.status === 401) {
        localStorage.removeItem('token')
        if (window.location.href.indexOf('login') === -1) {
          window.location.href = '/auth/login'
        }
      }
    }
    if (
      !(
        response.headers.has('Content-Type') &&
        response.headers.get('Content-Type').includes('application/json')
      )
    ) {
      error.body = {
        message: 'Response is not JSON',
      }
    } else {
      if (json.errors) error.body = json.errors
      if (json.error) error.body = json.error
      if (json.message) error.body = json.message
    }
    throw error
  } else {
    return json
  }
}

export default function request(url, options) {
  return fetch(url, options).then(checkStatus)
}

export function get(module, url, method = 'GET', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  headers = Object.assign(headers, defaultHeader)

  return request(`${apiRoot[module]}${url}`, {
    method,
    headers,
  })
}

export function post(module, url, postData, method = 'POST', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  headers = Object.assign(headers, defaultHeader)

  return request(`${apiRoot[module]}${url}`, {
    method,
    headers,
    body: JSON.stringify(postData),
  })
}
export function postWithAuth(module, url, email, password) {
  const defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa(`${email}:${password}`),
    'X-Tenant': window.location.host.split('.')[0],
  }
  return request(`${apiRoot[module]}${url}`, {
    method: 'POST',
    headers: defaultHeader,
  })
}
export function put(module, url, putData, headers = {}) {
  return post(module, url, putData, 'PUT', headers)
}

export function destroy(module, url, headers = {}) {
  return get(module, url, 'DELETE', headers)
}

export function upload(module, url, data, method = 'POST', headers = {}) {
  let defaultHeader = {
    Authorization: token ? `Bearer ${token}` : undefined,
  }
  headers = Object.assign(headers, defaultHeader)
  let formData = new FormData()
  if (data) {
    Object.keys(data).map((key) => {
      if (key === 'file') {
        formData.append('file', data[key], data[key].name)
      } else {
        if (Array.isArray(data[key])) {
          data[key].forEach((val) => {
            formData.append(`${key}[]`, val)
          })
        } else {
          formData.append(key, data[key])
        }
      }
    })
  }
  return request(`${apiRoot[module]}${url}`, {
    method,
    headers,
    body: formData,
  })
}
