import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectTokenId, myPermission, myRole } from './user/selectors'
import { getMyPermission } from './user/actions'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))
const DefaultAdminLayout = React.lazy(() => import('../layout/DefaultAdminLayout'))
const DefaultAuthLayout = React.lazy(() => import('../layout/DefaultAuthLayout'))
const DefaultClientLayout = React.lazy(() => import('../layout/DefaultClientLayout'))
const DefaultProviderLayout = React.lazy(() => import('../layout/DefaultProviderLayout'))

// Pages
const Page404 = React.lazy(() => import('../views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('../views/pages/page500/Page500'))

const AppNavigator = (props) => {
  const dispatch = useDispatch()
  const role = useSelector((state) => myRole(state))
  const token = useSelector((state) => makeSelectTokenId(state))
  const permissions = useSelector((state) => myPermission(state))
  const [destination, setDestination] = useState('')

  useEffect(() => {
    if (props.token) {
      if (props.role === 'Admin') {
        setDestination('/admin/dashboard')
      } else if (props.role === 'Provider') {
        setDestination('/provider/home')
      } else if (props.role === 'Client') {
        setDestination('/client/home')
      } else {
        setDestination('/landing/home')
      }
    } else {
      setDestination('/landing/home')
    }
    if (props.token && !props.role) {
      dispatch(getMyPermission()).then()
    }
  }, [])

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route
            path="/admin/*"
            name="Admin Home"
            render={(props) => (
              <DefaultAdminLayout {...props} role={role} token={token} permissions={permissions} />
            )}
          />
          <Route path="/auth/*" name="Auth" render={(props) => <DefaultAuthLayout {...props} />} />
          <Route
            path="/landing/*"
            name="Landing"
            render={(props) => (
              <DefaultLayout {...props} role={role} token={token} permissions={permissions} />
            )}
          />
          <Route
            path="/client/*"
            name="Client Page"
            render={(props) => (
              <DefaultClientLayout {...props} role={role} token={token} permissions={permissions} />
            )}
          />
          <Route
            path="/provider/*"
            name="Provider Page"
            render={(props) => (
              <DefaultProviderLayout
                {...props}
                role={role}
                token={token}
                permissions={permissions}
              />
            )}
          />
          {/*<Redirect from="/" to={this.props.token ? '/admin/dashboard' : '/landing'} />*/}
          <Redirect from="/admin/*" to={props.token ? '/admin/dashboard' : '/auth/login'} />
          <Redirect from="/auth/*" to={props.token ? '/admin/dashboard' : '/auth/login'} />
          <Redirect from="/client/*" to={props.token ? '/client/home' : '/auth/login'} />
          <Redirect from="/provider/*" to={props.token ? '/provider/home' : '/auth/login'} />
          <Redirect from="/" to={destination} />
          <Redirect from="/landing" to={props.token ? '/client/home' : '/landing/home'} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default React.memo(AppNavigator)
