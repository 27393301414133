import { createTypes, createAction, createSetTypes } from 'utils/actions'

import { get, put, destroy, post, upload } from 'utils/request'
import {
  showErrorToastAction,
  showSpinnerAction,
  hideSpinnerAction,
  showSuccessToastAction,
} from '../../store/actions/global'

const PROVIDER_EVENT = createTypes('EOC/PROVIDER_EVENT/EVENT')
const SELECTED_EVENT_ID = createSetTypes('EOC/PROVIDER_EVENT/SELECTED_EVENT_ID')

const setEventIdAction = {
  set: (data) => createAction(SELECTED_EVENT_ID.SET, { data }),
  clear: () => createAction(SELECTED_EVENT_ID.CLEAR, null),
}
const getEventAction = {
  do: () => createAction(PROVIDER_EVENT.DO, {}),
  success: (data) => createAction(PROVIDER_EVENT.SUCCESS, { data }),
  list: (data) => createAction(PROVIDER_EVENT.LIST, { data }),
  update: (id, data) => createAction(PROVIDER_EVENT.UPDATE, { id, data }),
  delete: (id) => createAction(PROVIDER_EVENT.DELETE, { id }),
  failed: (error) => createAction(PROVIDER_EVENT.FAILED, { error }),
}

const getEvents = () => async (dispatch) => {
  try {
    dispatch(getEventAction.do())
    dispatch(showSpinnerAction())
    const resp = await get('base', `oes/get-events?page_length=10&page=1`)
    dispatch(getEventAction.list(resp.data))
    dispatch(hideSpinnerAction())
  } catch (error) {
    dispatch(getEventAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
  }
}

const updateEvent = (id, data) => async (dispatch) => {
  try {
    dispatch(getEventAction.do())
    dispatch(showSpinnerAction())
    let resp = null
    if (id) {
      resp = await put('client', `event/${id}`, data)
      dispatch(getEventAction.update(id, data))
    } else {
      resp = await post('client', `event`, data)
      // dispatch(getEventAction.list(resp))
    }
    dispatch(hideSpinnerAction())
    dispatch(showSuccessToastAction('Event updated successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getEventAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteEvent = (id) => async (dispatch) => {
  try {
    dispatch(getEventAction.do())
    dispatch(showSpinnerAction())
    const resp = await destroy('client', `event/${id}`)
    dispatch(getEventAction.delete(id))
    dispatch(hideSpinnerAction())
    dispatch(showSuccessToastAction('Event deleted successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getEventAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const setEventId = (id) => (dispatch) => {
  if (id) {
    dispatch(setEventIdAction.set(id))
  } else {
    dispatch(setEventIdAction.clear())
  }
}
export { PROVIDER_EVENT, SELECTED_EVENT_ID, getEvents, updateEvent, deleteEvent, setEventId }
