import { createTypes, createAction, createSetTypes } from 'utils/actions'

import { get, put, destroy, post, upload } from 'utils/request'
import {
  showErrorToastAction,
  showSpinnerAction,
  hideSpinnerAction,
  showSuccessToastAction,
} from '../../store/actions/global'

const GET_USERS = createTypes('EOC/USERS/LIST')
const GET_MESSAGES = createTypes('EOC/MESSAGES/LIST')
const SEND_MESSAGES = createTypes('EOC/MESSAGES/SEND')
const RECEIVE_MESSAGES = createTypes('EOC/MESSAGES/RECEIVE')
const SELECTED_USER_ID = createSetTypes('EOC/MESSAGE/SELECTED_USER_ID')

const getUserListAction = {
  do: () => createAction(GET_USERS.DO, {}),
  success: (data) => createAction(GET_USERS.SUCCESS, { data }),
  list: (data) => createAction(GET_USERS.LIST, { data }),
  update: (id, data) => createAction(GET_USERS.UPDATE, { id, data }),
  delete: (id) => createAction(GET_USERS.DELETE, { id }),
  failed: (error) => createAction(GET_USERS.FAILED, { error }),
}
const getMessageAction = {
  do: () => createAction(GET_MESSAGES.DO, {}),
  success: (data) => createAction(GET_MESSAGES.SUCCESS, { data }),
  list: (data) => createAction(GET_MESSAGES.LIST, { data }),
  update: (id, data) => createAction(GET_MESSAGES.UPDATE, { id, data }),
  delete: (id) => createAction(GET_MESSAGES.DELETE, { id }),
  failed: (error) => createAction(GET_MESSAGES.FAILED, { error }),
}
const sendMessageAction = {
  do: (data) => createAction(SEND_MESSAGES.DO, { data }),
  success: (data) => createAction(SEND_MESSAGES.SUCCESS, { data }),
  list: (data) => createAction(SEND_MESSAGES.LIST, { data }),
  update: (data, messageId) => createAction(SEND_MESSAGES.UPDATE, { messageId, data }),
  delete: (id) => createAction(SEND_MESSAGES.DELETE, { id }),
  failed: (error) => createAction(SEND_MESSAGES.FAILED, { error }),
}
const receiveMessageAction = {
  success: (data) => createAction(RECEIVE_MESSAGES.SUCCESS, { data }),
}
const setUserIdAction = {
  set: (data) => createAction(SELECTED_USER_ID.SET, { data }),
  clear: () => createAction(SELECTED_USER_ID.CLEAR, null),
}

const fetchMessages = (data) => async (dispatch) => {
  try {
    dispatch(getMessageAction.do())
    dispatch(showSpinnerAction())
    const resp = await post('base', 'message/list', data)
    dispatch(getMessageAction.list(resp.data))
    dispatch(hideSpinnerAction())
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getMessageAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const sendMessage = (id, data) => async (dispatch) => {
  try {
    dispatch(sendMessageAction.do(data))
    const resp = await post('base', `message/send`, data)
    if (resp && resp['message_id']) {
      dispatch(sendMessageAction.update(data, resp['message_id']))
    }
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(sendMessageAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const deleteMessage = (id) => async (dispatch) => {
  try {
    dispatch(getMessageAction.do())
    dispatch(showSpinnerAction())
    const resp = await destroy('base', `message/${id}`)
    dispatch(getMessageAction.delete(id))
    dispatch(hideSpinnerAction())
    dispatch(showSuccessToastAction('Event deleted successfully.'))
    return Promise.resolve(resp)
  } catch (error) {
    dispatch(getMessageAction.failed(error.body))
    dispatch(showErrorToastAction(error.body))
    dispatch(hideSpinnerAction())
    return Promise.reject(error)
  }
}

const getUserList = (data) => async (dispatch) => {
  try {
    // dispatch(getUserListAction.do())
    const resp = await post('base', `message/users?page_length=10&page=1`, data)
    // dispatch(getUserListAction.success(id))
    return Promise.resolve(resp)
  } catch (error) {
    // dispatch(getUserListAction.failed(error.body))
    return Promise.reject(error)
  }
}

const setUserId = (id) => (dispatch) => {
  if (id) {
    dispatch(setUserIdAction.set(id))
  } else {
    dispatch(setUserIdAction.clear())
  }
}

const setCurrentUserChannel = (id) => (dispatch) => {
  if (id) {
    window.Echo.leave(`chat.dm.${id}`)
    console.log(`chat.dm.${id}`)
    window.Echo.private(`chat.dm.${id}`).listen('MessageSent', (event) => {
      console.log('listen', event.message)
      dispatch(receiveMessageAction.success(event.message))
    })
  }
}

export {
  GET_MESSAGES,
  SELECTED_USER_ID,
  SEND_MESSAGES,
  RECEIVE_MESSAGES,
  GET_USERS,
  getUserList,
  fetchMessages,
  sendMessage,
  deleteMessage,
  setUserId,
  setCurrentUserChannel,
}
