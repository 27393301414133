import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const MultiSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  disabled = false,
  limit = 1000,
}) => {
  const [optionList, setOptionList] = useState([])
  useEffect(() => {
    setOptionList(options)
  }, [options])
  const onChange = (option: any, actionMeta: any) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        option = options.filter((v) => v.isFixed)
        break
    }

    form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value)
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value)
    } else {
      return []
    }
  }

  const style = {
    control: (base) => ({
      ...base,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? '#E95D08'
          : undefined,
        color: isDisabled ? undefined : isSelected ? data.color : isFocused ? '#fff' : undefined,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? data.color : '#E95D08') : undefined,
        },
      }
    },
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: '#66a5fd' } : base
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed || disabled ? { ...base, display: 'none' } : base
    },
  }

  const isLimited = () => {
    if (isMulti) {
      return field.value.length >= limit
    } else {
      return false
    }
  }

  return (
    <Select
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isDisabled={disabled}
      styles={style}
      isClearable={getValue().some((v) => !v.isFixed)}
      isOptionDisabled={() => isLimited()}
    />
  )
}
export default MultiSelect
