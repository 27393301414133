import React, { useRef, useState } from 'react'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const PreviewHtml = ({ children, isDownload = false, onScrolledDown = () => {}, ...props }) => {
  const iframeRef = useRef(null)
  const [isConverting, setIsConverting] = useState(false)
  const handleScroll = () => {
    const iframeWindow = iframeRef.current.contentWindow
    if (
      iframeWindow.document.body.scrollHeight - iframeWindow.scrollY <=
      iframeWindow.innerHeight
    ) {
      console.log('dao le!')
      onScrolledDown()
    }
  }
  const handleDownload = () => {
    setIsConverting(true)
    setTimeout(() => {
      const iframeWindow = iframeRef.current.contentWindow
      const iframeContent = iframeWindow.document.body
      console.log('iframeWindow', iframeWindow.document)
      // html2canvas(iframeContent, { width: iframeContent.scrollWidth }).then((canvas) => {
      //   const imgData = canvas.toDataURL('image/jpeg', 1.0)
      //   const pdf = new jsPDF('p', 'mm', 'a4')
      //   pdf.addImage(imgData, 'JPEG', 0, 0)
      //   pdf.save('client_agreement.pdf')
      //   setIsConverting(false)
      // })
      const doc = new jsPDF({
        format: 'a4',
        unit: 'px',
      })
      doc.html(iframeContent, {
        margin: 10,
        async callback(doc) {
          await doc.save('client_agreement.pdf')
          setIsConverting(false)
        },
      })
    }, 1000)
  }
  const handleIframeLoad = () => {
    const iframeWindow = iframeRef.current.contentWindow
    iframeWindow.addEventListener('scroll', handleScroll)
    setTimeout(() => {
      handleScroll()
    }, 1000)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <iframe
        {...props}
        sandbox={'allow-scripts allow-same-origin'}
        srcDoc={children}
        ref={iframeRef}
        onLoad={handleIframeLoad}
        style={{ ...props.style, width: isConverting ? 450 : '100%' }}
      />
      {isDownload && (
        <a
          onClick={handleDownload}
          className="btn btn-primary"
          rel="noreferrer"
          style={{ alignSelf: 'flex-start', marginTop: 10 }}
        >
          <i className="fas fa-download" /> Download PDF
        </a>
      )}
    </div>
  )
}
export default PreviewHtml
