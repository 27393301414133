import apiRoot from '../../_data/apiRoot'

export const selectAuth = (state) => state.get('auth')

export const makeSelectTokenId = (state) => selectAuth(state).getIn(['token', 'id'])
export const getEmailVerifiedAt = (state) => selectAuth(state).getIn(['user', 'email_verified_at'])
export const getStatusLoading = (state) => selectAuth(state).getIn(['status', 'loading'])
export const getStatusError = (state) => selectAuth(state).getIn(['status', 'error'])
export const getUser = (state) => selectAuth(state).getIn(['user'])
export const getUserInformation = (state) => {
  const full_name = selectAuth(state).getIn(['user', 'full_name'])
  const email = selectAuth(state).getIn(['user', 'email'])
  const id = selectAuth(state).getIn(['user', 'id'])
  let profile_img = selectAuth(state).getIn(['user', 'profile_img'])
  let membership = selectAuth(state).getIn(['user', 'membership'])
  if (profile_img) {
    profile_img = apiRoot['root'] + profile_img
  }
  return { full_name, email, id, profile_img, membership }
}
export const getAppName = (state) => selectAuth(state).getIn(['appName'])
export const myPermission = (state) => selectAuth(state).getIn(['permissions'])
export const myRole = (state) => selectAuth(state).getIn(['role'])
