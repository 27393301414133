import { fromJS, List } from 'immutable'

import pipeMutators from 'utils/mutator'
import { REHYDRATE } from 'redux-persist'
import { GET_MESSAGES, SEND_MESSAGES, SELECTED_USER_ID, RECEIVE_MESSAGES } from './actions'

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)

const setData = (key, data) => (state) => state.setIn([key], data)
const updateData = (key, id, data) => (state) =>
  state.setIn([key, state.get(key).findIndex((item) => item.id === id)], data)

const addData = (key, data) => (state) => state.mergeIn([key], [data])

const deleteData = (key, id) => (state) =>
  state.deleteIn([key, state.get(key).findIndex((item) => item.id === id)])

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'loading'], true)

const resetStatusSetError = (error) => (state) =>
  resetStatus()(state).setIn(['status', 'error'], error || {})

const resetUserId = (id) => (state) => resetStatus()(state).setIn(['selected_user_id'], id)

const DEFAULT_STATUS = {
  loading: false,
  error: null,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
  messages: List([]),
  selected_user_id: '',
})

function clientReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case REHYDRATE:
      if (action.payload.client) return resetStatus()(action.payload.client)
      return state
    case GET_MESSAGES.DO:
      return mutate([resetStatusSetLoading(), setData('messages', [])])
    case GET_MESSAGES.SUCCESS:
    case GET_MESSAGES.LIST:
      return mutate([setData('messages', action.data), resetStatus()])
    case GET_MESSAGES.UPDATE:
      return mutate([updateData('messages', action.id, action.data), resetStatus()])
    case GET_MESSAGES.DELETE:
      return mutate([deleteData('messages', action.id), resetStatus()])
    case GET_MESSAGES.FAILED:
      return mutate([resetStatusSetError(action.error)])

    case SEND_MESSAGES.DO:
      return mutate([addData('messages', action.data)])
    case SEND_MESSAGES.UPDATE:
      const originalMessageId = action.data.id
      let updatedData = JSON.parse(JSON.stringify(action.data))
      updatedData['id'] = action.messageId
      return mutate([updateData('messages', originalMessageId, updatedData), resetStatus()])
    case SELECTED_USER_ID.SET:
      return mutate([resetUserId(action.data)])
    case SELECTED_USER_ID.CLEAR:
      return mutate([resetUserId('')])

    case RECEIVE_MESSAGES.SUCCESS:
      return mutate([addData('messages', action.data)])
    default:
      return state
  }
}

export default clientReducer
