import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'

const CreatableMultiSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  limit = 1000,
}) => {
  const [optionList, setOptionList] = useState([])
  useEffect(() => {
    setOptionList(options)
  }, [options])
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? option.map((item) => {
            if (item['__isNew__']) {
              const tempOptionList = JSON.parse(JSON.stringify(optionList))
              tempOptionList.push({ value: item.value, label: item.label, selected: false })
              setOptionList(tempOptionList)
            }
            return item.value
          })
        : option.value,
    )
  }
  const getValue = () => {
    if (optionList && optionList.length) {
      return isMulti
        ? optionList.filter((option) => field.value.indexOf(option.value) >= 0)
        : optionList.find((option) => option.value === field.value)
    } else {
      return []
    }
  }

  const isLimited = () => {
    if (isMulti) {
      return field.value.length >= limit
    } else {
      return false
    }
  }
  return (
    <CreatableSelect
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={optionList}
      isMulti={isMulti}
      isOptionDisabled={() => isLimited()}
    />
  )
}
export default CreatableMultiSelect
