import { fromJS, List } from 'immutable'

import pipeMutators from 'utils/mutator'
import { REHYDRATE } from 'redux-persist'
import {
  CONTENT,
  KEY,
  OESTYPE,
  SPECIALTIES,
  CERTIFICATIONS,
  MAJORCITIES,
  NOTIFICATION,
} from './actions'

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setData = (key, data) => (state) => state.setIn([key], data)
const updateData = (key, id, data) => (state) =>
  state.setIn([key, state.get(key).findIndex((item) => item.id === id)], data)

const deleteData = (key, id) => (state) =>
  state.deleteIn([key, state.get(key).findIndex((item) => item.id === id)])

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'loading'], true)

const resetStatusSetError = (error) => (state) =>
  resetStatus()(state).setIn(['status', 'error'], error || {})

const DEFAULT_STATUS = {
  loading: false,
  error: null,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
  keys: List([]),
  contents: List([]),
  oesTypes: List([]),
  specialties: List([]),
  majorcities: List([]),
  certifications: List([]),
  notifications: List([]),
})

function adminReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case REHYDRATE:
      if (action.payload.admin) return resetStatus()(action.payload.admin)
      return state
    case KEY.DO:
    case CONTENT.DO:
      return mutate([resetStatusSetLoading()])
    case KEY.SUCCESS:
    case KEY.LIST:
      return mutate([setData('keys', action.data), resetStatus()])
    case KEY.UPDATE:
      return mutate([updateData('keys', action.id, action.data), resetStatus()])
    case KEY.DELETE:
      return mutate([deleteData('keys', action.id), resetStatus()])
    case CONTENT.FAILED:
    case KEY.FAILED:
      return mutate([resetStatusSetError(action.error)])
    case CONTENT.SUCCESS:
    case CONTENT.LIST:
      return mutate([setData('contents', action.data), resetStatus()])
    case CONTENT.UPDATE:
      return mutate([updateData('contents', action.id, action.data), resetStatus()])
    case CONTENT.DELETE:
      return mutate([deleteData('contents', action.id), resetStatus()])

    case OESTYPE.DO:
      return mutate([resetStatusSetLoading(), setData('oesTypes', []), resetStatus()])
    case OESTYPE.SUCCESS:
    case OESTYPE.LIST:
      return mutate([setData('oesTypes', action.data), resetStatus()])
    case OESTYPE.UPDATE:
      return mutate([updateData('oesTypes', action.id, action.data), resetStatus()])
    case OESTYPE.DELETE:
      return mutate([deleteData('oesTypes', action.id), resetStatus()])
    case OESTYPE.FAILED:
      return mutate([resetStatusSetError(action.error), setData('oesTypes', []), resetStatus()])

    case SPECIALTIES.DO:
      return mutate([resetStatusSetLoading(), setData('specialties', []), resetStatus()])
    case SPECIALTIES.SUCCESS:
    case SPECIALTIES.LIST:
      return mutate([setData('specialties', action.data), resetStatus()])
    case SPECIALTIES.UPDATE:
      return mutate([updateData('specialties', action.id, action.data), resetStatus()])
    case SPECIALTIES.DELETE:
      return mutate([deleteData('specialties', action.id), resetStatus()])
    case SPECIALTIES.FAILED:
      return mutate([resetStatusSetError(action.error), setData('specialties', []), resetStatus()])

    case MAJORCITIES.DO:
      return mutate([resetStatusSetLoading(), setData('majorcities', []), resetStatus()])
    case MAJORCITIES.SUCCESS:
    case MAJORCITIES.LIST:
      return mutate([setData('majorcities', action.data), resetStatus()])
    case MAJORCITIES.UPDATE:
      return mutate([updateData('majorcities', action.id, action.data), resetStatus()])
    case MAJORCITIES.DELETE:
      return mutate([deleteData('majorcities', action.id), resetStatus()])
    case MAJORCITIES.FAILED:
      return mutate([resetStatusSetError(action.error), setData('majorcities', []), resetStatus()])

    case CERTIFICATIONS.DO:
      return mutate([resetStatusSetLoading(), setData('certifications', []), resetStatus()])
    case CERTIFICATIONS.SUCCESS:
    case CERTIFICATIONS.LIST:
      return mutate([setData('certifications', action.data), resetStatus()])
    case CERTIFICATIONS.UPDATE:
      return mutate([updateData('certifications', action.id, action.data), resetStatus()])
    case CERTIFICATIONS.DELETE:
      return mutate([deleteData('certifications', action.id), resetStatus()])
    case CERTIFICATIONS.FAILED:
      return mutate([
        resetStatusSetError(action.error),
        setData('certifications', []),
        resetStatus(),
      ])
    case NOTIFICATION.DO:
      return mutate([resetStatusSetLoading(), setData('notifications', []), resetStatus()])
    case NOTIFICATION.SUCCESS:
    case NOTIFICATION.LIST:
      return mutate([setData('notifications', action.data), resetStatus()])
    case NOTIFICATION.UPDATE:
      return mutate([updateData('notifications', action.id, action.data), resetStatus()])
    case NOTIFICATION.DELETE:
      return mutate([deleteData('notifications', action.id), resetStatus()])
    case NOTIFICATION.FAILED:
      return mutate([
        resetStatusSetError(action.error),
        setData('notifications', []),
        resetStatus(),
      ])

    default:
      return state
  }
}

export default adminReducer
