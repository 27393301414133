import React, { useEffect } from 'react'
import { CToast, CToastBody, CToastClose } from '@coreui/react-pro'
import { useDispatch } from 'react-redux'
import { hideErrorToastAction } from '../../store/actions/global'

const ErrorToast = ({ errors }) => {
  const dispatch = useDispatch()
  const dismiss = () => dispatch(hideErrorToastAction())
  useEffect(() => {
    return () => {
      // dispatch(hideErrorToastAction())
    }
  }, [dispatch])
  return (
    <CToast
      autohide={true}
      visible={true}
      color="danger"
      className="text-white align-items-center"
      onClose={() => dismiss()}
    >
      <div className="d-flex">
        <CToastBody>
          {typeof errors === 'object' &&
            Object.values(errors).map((error, key) => (
              <p className="text-white" key={key}>
                {error[0]}
              </p>
            ))}
          {typeof errors === 'string' && <p className="text-white">{errors}</p>}
        </CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )
}
export default ErrorToast
