import Echo from 'laravel-echo'

export const messageEchoInit = (token) => {
  window.Pusher = require('pusher-js')
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    wsHost: process.env.REACT_APP_WS_HOST_URL,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    forceTLS: false,
    authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    },
  })
}
