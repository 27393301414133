import { CButton, CButtonGroup, CCol } from '@coreui/react-pro'
import React from 'react'

const TableAction = () => {
  return (
    <CCol md={12} className="mt-3">
      <CButtonGroup role="group" className="pull-right" aria-label="Action group">
        <CButton color="white" className="shadow-none">
          <img src="/svgs/pdf.svg" width="30" alt="" /> &nbsp;Download as PDF
        </CButton>
        <CButton color="white" className="shadow-none">
          <img src="/svgs/print.svg" width="30" alt="" /> &nbsp;Print
        </CButton>
      </CButtonGroup>
    </CCol>
  )
}
export default TableAction
